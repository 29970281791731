
export default function DataFormat (Answer: any) {
    Answer=Answer.replaceAll('[r]', `<span class="text-red">`).replaceAll('[/r]', `</span>`)
    Answer=Answer.replaceAll('[bl]', `<span class="text-blue">`).replaceAll('[/bl]', `</span>`)
    Answer=Answer.replaceAll('[g]', `<span class="text-green">`).replaceAll('[/g]', `</span>`)
    Answer=Answer.replaceAll('[pk]', `<span class="text-pink">`).replaceAll('[/pk]', `</span>`)
    Answer=Answer.replaceAll('[prp]', `<span class="text-purple">`).replaceAll('[/prp]', `</span>`)
    Answer=Answer.replaceAll('[t]', `<span class="text-teal">`).replaceAll('[/t]', `</span>`)
    Answer=Answer.replaceAll('[t]', `<span class="text-teal">`).replaceAll('[/t]', `</span>`)
    Answer=Answer.replaceAll('[b]', `<span style="text-bold">`).replaceAll('[/b]', `</span>`)
    Answer=Answer.replaceAll('[dbr]', `<span>&nbsp; <br /> &nbsp; <br /></span>`)
    Answer=<div dangerouslySetInnerHTML={{__html: Answer}}/>    
    return Answer
  }