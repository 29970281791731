import { FetchYoutube } from "../services/YoutubeFetchService";
import TwitchComponent from "./Twitch";
import ReactGA from 'react-ga';
import { useEffect } from "react";
import FetchPoB from "../services/FetchLatestBuildsService";
import FetchGuides from "../services/FetchLatestGuidesService";


export default function ContentComponent(){

    const version = 3.25
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
      },[])

    return (
        <div className="ContentWrapper">
            <h2>Latest Builds & Streams</h2>
            <div className="Content-Top">
                <div className="BuildContent">
                    <h2>{version} Builds</h2>
                    <FetchPoB />
                </div>
                <div className="BuildContent">
                    <h2>{version} Guides</h2>
                    <FetchGuides />
                </div>
                <div className="TwitchContent">
                    <TwitchComponent />
                </div>
            </div>
            <div className="Content-Bottom">
            <h2>Latest Vids</h2>
                    <FetchYoutube />
            </div>
        </div>
    );
}
