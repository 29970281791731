import { Button, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";

  const theme = createTheme({
    components:{
        MuiButton:{
            styleOverrides:{
                root:{
                    fontSize: '10px',
                    height: '24px', 
                    width:'120px',                     
                    lineHeight: '10px',
                    textAlign: 'center',
                    marginTop: '1px'
                },
            },
        },        
    },
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#f57c00',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#ffa726',
      },
    },
  });

const CustomLinks = () => {
    return (
      <div className='App-CustomLinks'>
            <div className="Url">
                <ThemeProvider theme={theme}> 
                    <Button id="0" 
                            variant="outlined" 
                            href="https://github.com/SnosMe/awakened-poe-trade" 
                            target="_blank" 
                            rel="noreferrer">
                        Awakened Trade
                    </Button>
                </ThemeProvider>
                
            </div>	
            <div className="Url">
                <ThemeProvider theme={theme}> 
                    <Button id="1" 
                            variant="outlined" 
                            href="https://www.poewiki.net/wiki/Path_of_Exile_Wiki" 
                            target="_blank" 
                            rel="noreferrer" >
                        PoE Wiki
                    </Button>
                </ThemeProvider>
            </div>			

            <div className="Url">
                <ThemeProvider theme={theme}> 
                    <Button id="2" 
                            variant="outlined" 
                            href="https://www.craftofexile.com/" 
                            target="_blank" 
                            rel="noreferrer" 
                             >
                        Craft of Exile 
                    </Button>
                </ThemeProvider>
            </div>
            <div className="Url">
                 <ThemeProvider theme={theme}> 
                    <Button id="3"
                            variant="outlined"
                            href="https://poedb.tw/us/"
                            target="_blank"
                            rel="noreferrer">
                        poedb 
                    </Button>
                </ThemeProvider>
            </div>
            <div className="Url">
                 <ThemeProvider theme={theme}> 
                    <Button id="4"
                            variant="outlined"
                            href="https://gem-progression.vercel.app/"
                            target="_blank"
                            rel="noreferrer">
                        RF Gem Progression 
                    </Button>
                </ThemeProvider>
            </div>
            <div className="Url">
                <ThemeProvider theme={theme}> 
                    <Button id="5"
                            variant="outlined"
                            href="https://www.pathofexile.com/trade/search"
                            target="_blank"
                            rel="noreferrer"
                             >
                         Trade Site
                    </Button>
                </ThemeProvider>
                </div> 
            <div className="Url">
                <ThemeProvider theme={theme}> 
                    <Button id="6"
                            variant="outlined"
                            href="https://pathofbuilding.community/"
                            target="_blank"
                            rel="noreferrer"
                             >
                         PoB Community
                    </Button>
                </ThemeProvider>
            </div> 
            <div className="Url">
                <ThemeProvider theme={theme}> 
                    <Button id="7"
                            variant="outlined"
                            href="https://grinding.zone/"
                            target="_blank"
                            rel="noreferrer"
                             >
                         Grinding Zone
                    </Button>
                </ThemeProvider>
            </div><br />
            </div>
    );
  };
    export default CustomLinks;