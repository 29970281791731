import { Button, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import PayPalIcon from "../assets/images/paypalpohx.png";


const theme = createTheme({
  components:{
    MuiButton:{
      styleOverrides:{
        outlined:{
          border: '2px solid',
        }
      }
    }
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#f57c00',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ffa726',
    },
  },
});

const DonationLinks = () => {
    return ( 
      <ThemeProvider theme={theme}> 
        <div className="Paypal">
          
          <Button variant="outlined" href='https://streamelements.com/pohx/tip'  target="_blank" rel="noopener noreferrer" style={{ margin: '.5rem', whiteSpace: 'nowrap', minWidth:'auto' }}>
          <img src={PayPalIcon} style={{ height: '30px', width: '30px', marginLeft:'-10px', marginRight:'2px'}}alt="dumb" />  
            &nbsp; <b style={{ fontSize: '16px'}}>Help Keep The Site Running!</b>&nbsp;
          <img src={PayPalIcon} style={{ height: '30px', width: '30px', marginLeft:'2px', marginRight:'-10px'}}alt="dumb" />
          </Button>            
        </div>	
      </ThemeProvider>
    );
};
  export default DonationLinks;