
import { Card, CardContent, CardMedia, createTheme, ThemeProvider, Typography } from '@mui/material';

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#f57c00',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#ffa726',
      },
    },
  });

export const YouTubeComponent = (props:any) => (
        <div>
            <ThemeProvider theme={theme}>        
                <Card sx={{ maxWidth: 345 }} 
                      style={{
                        backgroundColor: "#282c34",
                        color: "#ff8800",
                        width: '0',
                        minWidth: '90%',
                        minHeight: '90%',
                        border: '1px solid #f80',
                        boxShadow: "0 3px 1px rgba(0, 0, 0, 15%), 0 2px 2px rgba(0, 0, 0, 35%), 0 1px 5px rgb(0 0 0 / 25%)"
                        }} 
                      variant="outlined">
                    <a target="_blank" rel="noreferrer" href={props.video.link}>
                        <CardMedia
                        component="img"
                        height="194"
                        image={`https://i4.ytimg.com/vi/${props.video.guid.split(':')[2]}/mqdefault.jpg`}
                        alt={`https://i4.ytimg.com/vi/${props.video.guid.split(':')[2]}/mqdefault.jpg`}
                        />
                    </a>

                    <CardContent style={{                        
                        borderTop: '1px solid #f80',
                        boxShadow: "0 3px 1px rgba(0, 0, 0, 15%), 0 2px 2px rgba(0, 0, 0, 35%), 0 1px 5px rgb(0 0 0 / 25%)"
                        }} >
                        <Typography variant="body2" color="ff8800">
                            {titleFormat(props.video.title)}
                        </Typography>
                    </CardContent>
                </Card>       
            </ThemeProvider>     
        </div>
);

function titleFormat(title: any){
 title = title.replaceAll('&amp;', '&')
 return title;
}