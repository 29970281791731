import { useEffect } from "react";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  components:{
    MuiButton:{
      styleOverrides:{
        root:{
          fontSize: '12px',
          height: '24px', 
          minWidth:'30%',
          maxWidth: '30%',                     
          lineHeight: '10px',
          textAlign: 'center',
          marginTop: '1px',
          padding: '1px',
          fontFamily:'Open Sans,Arial, Helvetica Neue ,Helvetica,sans-serif',
          minHeight: '1rem',
          display: 'inline-block', 
          textTransform: 'none', 
          backgroundImage: 'linear-gradient(to bottom right, rgba(1, 50, 1), rgba(0, 25, 0),rgb(0, 10, 0))',
          textShadow:'1px 1px 2px black',
          boxShadow:'0 3px 1px rgb(0 0 0 / 25%), 0 2px 2px rgb(0 0 0 / 25%), 0 1px 5px rgb(0 0 0 / 25%)',
        },
      },
    },        
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#f57c00',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ffa726',
    },
  },
});

// const aura = '☄'
// const boss ='☠'
// const curr ='$$'
// const damg ='⚔'
// const dfns ='⛨'
// const flsk ='⚱'
// const gear ='⛑'
// const gems ='♦'
// const infl ='♛'
// const labs ='☣'
// const levl ='✠'
// const lnks ='⛓'
// const maps ='◉'
// const pobs ='❖'
// const unqs ='☯'


// const aura = '💫'
// const boss ='💀'
// const curr ='💰'
// const damg ='🔥'
// const dfns ='🛡'
// const flsk ='🧪'
// const gear ='🧥'
// const gems ='💎'
// const infl ='👑'
// const labs ='🚪'
// const levl ='🌟'
// const lnks ='⛓'
// const maps ='🌎'
// const pobs ='📄'
// const unqs ='💈'

const inq = '🌟'
const chief ='🔥'
const jugg = '💀'

function Filter({setActiveFilter, activeFilter, setFiltered, resultData}: any){
    useEffect(() => {
      if(activeFilter===0){
        setFiltered(resultData);
        return;
      }
      const filtered = resultData.filter((data:any) => data.Tags.includes(activeFilter))  
      setFiltered(filtered) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter])
    const Tags2 = ["Inq", "Chief", "Jugg"]
    const Icon2 = [inq, chief, jugg]
    // const Tags = ["Aura", "Boss", "Currency", "Damage", "Defense", "Flask", "Gear", "Gem", "Influence", "Lab", "Level", "Links", "Maps", "POB", "Unique"]
    // const Icon = [aura, boss, curr, damg, dfns, flsk, gear, gems, infl, labs, levl, lnks, maps, pobs, unqs]
    return (        
      <div className="filter-container">
          <h2>Available Filters</h2>
            <ThemeProvider theme={theme}> 
              <Button id="0" variant="outlined" onClick ={()=> setActiveFilter(0)} >
                 <div><div style={{width:"85%", float:"left", paddingTop:"2px"}}>All</div>
                 <div style={{width:"9%",fontSize:"20px",float:"right",paddingLeft:"2px",paddingBottom:"2px", color:"#F80", textShadow:"1px 1px 2px black"}}>
                   &#9901;
                   </div>
                   </div>
              </Button>
              {Tags2.map((item:string, i:any)=>(                  
                  <Button id={i} variant="outlined" onClick={()=>setActiveFilter(item)}>
                     <div>
                      <div style={{width:"82%", float:"left"}}>{item}</div>
                     <div style={{width:"16%",fontSize:"18px",float:"right", color:"#F80", textShadow:"1px 1px 2px black"}}>{Icon2[i]}</div>
                     </div>                    
                  </Button>                  
              ))}                
            </ThemeProvider>
      </div>
    )
}

export default Filter;

