import { BrowserRouter as Router } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components:{
    MuiButton:{
      styleOverrides:{
        outlined:{
          border: '2px solid',
        }
      }
    }
  },
  typography: {
    fontSize: 16,
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#f57c00',
      
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ffa726',
    },
  },
});

const Routing = () => {
  return (
        <Router>
          <ThemeProvider theme={theme}> 
          <nav>
              <Button variant="outlined" href='/FAQ' style={{ margin: '.5rem'}}>
                RF FAQ
              </Button>
              <Button variant="outlined"  href='/Crafts' style={{ margin: '.5rem' }}>
                Crafts
              </Button>
              {/* <Button variant="outlined"  href='/League' style={{ margin: '.5rem' }}>
                League
              </Button> */}
              <Button variant="outlined"  href='/' style={{ margin: '.5rem' }}>
                Home
              </Button>
            </nav>
          </ThemeProvider>
        </Router>
  );
};
  export default Routing;