import DataFetch from "../services/DataFetchService";
import DataFormatter from "../services/DataFormatService";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Filter from "./Filter";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Modal, Typography } from "@mui/material";
import ReactGA from 'react-ga';

const theme = createTheme({
  components:{
    MuiButton:{
      styleOverrides:{
        root:{
          padding: '0px',
          textAlign:'left',
          lineHeight: "20px",
          fontFamily:'Open Sans,Arial, Helvetica Neue ,Helvetica,sans-serif',
          fontSize:'12px',
          width: '95%',
          minHeight: '1rem',
          display: 'inline-block', 
          float:'left',
          textTransform: 'none', 
          backgroundImage: 'linear-gradient(to bottom right, rgba(1, 50, 1), rgba(0, 25, 0),rgb(0, 10, 0))',
          textShadow:'1px 1px 2px black',
          boxShadow:'0 3px 1px rgb(0 0 0 / 25%), 0 2px 2px rgb(0 0 0 / 25%), 0 1px 5px rgb(0 0 0 / 25%)',
        },
      },
    },        
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: 'rgb(200,200,200)',
    },
    secondary: {
      // This is green.A700 as hex.
      main: 'rgb(200,200,200)',
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minHeight: '10rem',
  minWidth: '20rem',
  width: '80%',
  maxWidth: '60rem',
  color: 'rgb(255,255,255)',
  border: '2px solid rgb(255, 136, 0)',
  bgcolor: 'rgb(15,15,15)',
  boxShadow: 2,
  p: '16px',
};

//Questions & Answers URL
const dataSource = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQmg0-H44uxatPvosWwlPDwms03D40gx8heuge76C9xnxKXDi4mFQlqTUZ_cK-QawipP_l-NBxImXcw/pub?output=csv"
const QuestionComponent = () => {
    const [resultData, setResultData] = useState<any>();
    const [selectedData, setSelectedData] = useState<any>();
    const [filtered,setFiltered] = useState<any>([]);
    const [activeFilter, setActiveFilter] = useState(0);
    const [open, setOpen] = useState(false);

    const expandModal = (data:any) => {
        setSelectedData(data);
        setOpen(true);
    }

    const closeModal = () => {
        setSelectedData(null);
        setOpen(false);
    }

const response = Array.from(DataFetch(dataSource));
//const bullet ='\u2022'
const lineBreak = '[br]'
useEffect(()=>{
  ReactGA.pageview(window.location.pathname);
},[])

useEffect(()=>{
  setResultData(response);
  setFiltered(response);
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[response.length]) 

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function buildTagList() {
  return ''
}
    
  return (
    <div>
      <div className="RFFAQ">
        <Filter resultData={resultData} setFiltered={setFiltered} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
        
        <motion.div layout transition={{ duration: .1 }} className="QuestionListWrapper">  
          <AnimatePresence initial={false}>        
            {filtered?.map((data:any, i:number) => ( 
            <motion.div  
                transition={{ duration: .75 }}  
                initial={{opacity: 0}}
                animate={{opacity:1}} 
                exit={{opacity: 0}} 
                key={i} 
                className="QuestionWrapper">  
                            
                <ThemeProvider theme={theme}>                    
                    <Button 
                        variant="outlined" 
                        className="App-Question-Button" 
                            onClick={() => expandModal(data)}
                            >       
                      <div className="App-Question">{data.Question} 
                        <div className="App-Tag">
                          {data.Tags}
                        </div>
                      </div>                                                                
                    </Button>
                </ThemeProvider>
                      
            </motion.div>
            ))}
            </AnimatePresence>
        </motion.div>
        <Modal 
          disableScrollLock 
          open={open} 
          onClose={closeModal} 
          aria-labelledby="keep-mounted-modal-title" 
          aria-describedby="keep-mounted-modal-description">
          <Box sx={style}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            <h3><b><u>{selectedData && selectedData.Question}</u></b></h3>
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <div>                  
              {selectedData && selectedData.Answer.split(lineBreak).map((line:any) => <div>{DataFormatter(line)}</div>)}
              <div className="App-Url">                    
                <a href={selectedData && selectedData.Url} target="_blank" rel="noreferrer"> {selectedData && selectedData.Url} </a>
              </div>
              <br />   
              {              
              selectedData && selectedData.Image ===""
                ? <div>&nbsp;</div>
                : <div className="App-Images"><img src={selectedData && "https://i.gyazo.com/" + selectedData.Image + ".png"} className="App-Image" alt={selectedData && selectedData.Image}></img></div>
              }            
            </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default QuestionComponent;