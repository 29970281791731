import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderComponent from './components/Header';
import Routing from './components/Router';
import QuestionComponent from './components/Questions';
import ContentComponent from './components/Content';
import CraftsComponent from './components/Crafts';
import CustomLinks from './components/CustomLinks';
import DonationLinks from './components/DonationLinks';


function App() {
  return (
    <div className="App">
      <header className="App-Header">
        <div className="App-Header-Identifier">
          <HeaderComponent />      
          <div className="App-Navbar-Left">
              <DonationLinks />
            </div>  
          <div className="App-Navbar"> 
              <Routing />
          </div>
        </div>
      </header>
      <div id="App-Main"> 
        <Router>
          <Routes>
            <Route path="/" element={<ContentComponent />} />
            <Route path="/FAQ" element={<QuestionComponent />} />
            <Route path="/Crafts" element={<CraftsComponent />} />
            {/* <Route path="/League" element={<LeagueComponent />} /> */}
            <Route path="/" element={<ContentComponent />} />
          </Routes>
        </Router> 
      </div>
      <div className="LatestLinks">
        <CustomLinks />
      </div>
    </div>
  );
}

export default App;
