import React from "react";
import Papa from "papaparse";

export default function DataFetch (dataSource: any) {
  const [rows, setRows] = React.useState<any[]>([])
  React.useEffect(() => {
    async function getData() {
      const response = await fetch(dataSource)
      const reader = response.body!.getReader()
      const result = await reader.read() // raw array
      const decoder = new TextDecoder('utf-8')
      const csv = decoder.decode(result.value) // the csv text
      const results = Papa.parse(csv, { header: true, newline: "\r\n", skipEmptyLines: false,}) // object with { data, errors, meta }
      const rows = results.data // array of objects
      
      setRows(rows)      
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rows.length]) // [] means just do this once, after initial render
  return (
    rows    
  )
}