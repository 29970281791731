import { Button, createTheme, ThemeProvider } from "@mui/material";
import DataFetch from "./DataFetchService";

const theme = createTheme({
    components:{
        MuiButton:{
            styleOverrides:{
                root:{
                    fontSize: '10px',
                    height: '24px', 
                    width:'100px',                     
                    lineHeight: '10px',
                    textAlign: 'center',
                    marginTop: '1px'
                },
            },
        },        
    },
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#f57c00',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#ffa726',
      },
    },
  });

export default function FetchLatestBuildsService (){
const dataSource = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSb-ErYHTBLH265X33jmNYb7NWCAG4IWTg4CjYCYn-pRJFn1Phwwxi6aaJbZBDfQBcyYb6LS4OhM7wd/pub?output=csv"
const response = Array.from(DataFetch(dataSource));
const respReturn = response.map((data) =>
    <div className="BuildBlock">      
      <div className="Build-UrlTitle">
          {data.Title +" "}
      </div> 
      <div className="Build-Url">
      <ThemeProvider theme={theme}> 
            <Button id="1" variant="outlined" onClick={() =>  navigator.clipboard.writeText(data.Url)}>
              Copy Url
            </Button>
        </ThemeProvider>
      </div>
    </div>

)
 return (
    <div>
      {respReturn}
   </div>
 )}