const base="pohx.net";
const domain1="www.pohx.net";
const domain2="rf-wiki-pohx.netlify.app";
const domain3="localhost";

const TwitchComponent = () => {
    return (
        <iframe src={"https://player.twitch.tv/?channel=pohx&parent="+base+"&parent="+domain1+"&parent="+domain2+"&parent="+domain3+"&muted=true&autoplay=false"} title="Pohx's Stream" scrolling="no" height="400" width="712" 
        style={{
            border:"none",
            borderRadius: "10px",
            paddingTop: "2px",
            }}>

        </iframe>
    )}

   export default TwitchComponent;