import DataFetch from "../services/DataFetchService";
import DataFormatter from "../services/DataFormatService";
import ReactGA from 'react-ga';
import { useEffect } from "react";

const dataSource = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQRR68j5YM3sghb72Ol4j2M9iPkegKMt-CTjrUqbo2i_rZ15y27YW8AJQPmNrez6keslixjHQpsiWZO/pub?output=csv"
const lineBreak = '[br]'


export default function CraftsRecipeComponent(){
    const items = Array.from(DataFetch(dataSource)); 
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
      },[])   
    
  return (
    <div className="App-Crafts">  
        <div className="Crafts-Nav">
            {items.map((data, i) => (
                <a href={"#"+data.Slot} className="recipeNav">{data.Slot}</a>
            ))}
        </div>      
        <div className="Recipes">
            
                {items.map((data, i) => (   
                <div className="Crafts-Spacer" id={data.Slot}>  
                    <div className="Crafts-Wrapper" >
                        <div className="RecipeName">{data.Slot}:</div>
                        <div key={i} className="App-CraftItems">
                            <div className="CraftItem-Body"> 
                                <h3>Crafting Steps:</h3>                  
                                <div className="CraftSteps">{data.Steps?.split(lineBreak).map((line:any) =><p>{DataFormatter(line)}</p>)} </div>
                                <div className="App-Url"><a href={''+data.CraftUrl+''} target="_blank" rel="noreferrer"> {data.CraftUrl} </a></div>
                            </div>
                            <div className="CraftItem-Outcomes">
                                <div className="CraftGoals">
                                        {
                                            data.Goal===""
                                                ? <div>&nbsp;</div>
                                                : 
                                                <>
                                                <h3>Preferred Outcomes:</h3>
                                                {data.Goal?.split(lineBreak).map((line:any) =><p>{DataFormatter(line)}</p>)}
                                                </>                
                                        }
                                </div>
                                <div className="CraftImages">
                                        {              
                                            data.Image ===""
                                            ? <div>&nbsp;</div>
                                            : (
                                                <div className="App-Images">
                                                {data.CraftImage?.split(lineBreak).map((image:any) => 
                                                image === "" 
                                                ? <div>&nbsp;</div>
                                                : <img src={"https://i.gyazo.com/" +image.trim()+ ".png"} className="CraftImage" alt={data.Image}></img>
                                                )}
                                                </div>
                                                )
                                            }
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>      
                ))}
            
        </div>
    </div>
  );
};