import React, { useState, useEffect } from 'react';
import { YouTubeComponent } from '../components/YouTube';

export function FetchYoutube() {
    const [videos, setVideos] = useState([]);
    const currentChannelId = 'UCgv2_w0HveLN_JgFGTqwmTw';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [channelName, setChannelName] = useState();
    const [setSearchError] = useState<any>(); // Create searchError state
    const baseUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D';
    
    useEffect(() => {
        (async () => {
            if (currentChannelId) {
                try {
                    const data = await fetch(`${baseUrl}${currentChannelId}`).then(response => response.json());
                    if (!data.items) {
                        throw new Error();
                    }
                    setVideos(data.items);
                    setChannelName(data.items[0].author);
                    setSearchError(''); // Reset the error if a successful request is received
                } catch (error) {
                    setSearchError(`Couldn't retrieve videos, check your channel ID.`); // Set the error state if an error occurrs
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChannelId]);
    const qReturn = videos.map((video:any) => <div className="videos"><YouTubeComponent key={video.guid} video={video}/></div>);
    return (
        <div className='YT'>        
            <div className="containerVids">            
                {qReturn}
            </div>
        </div>
        );
    };
