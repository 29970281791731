
import CraftsRecipeComponent from './CraftsRecipes';
import ReactGA from 'react-ga';
import { useEffect } from "react";

export default function CraftsComponent(){
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
      },[])   
    
  return (
    <div>
        <CraftsRecipeComponent />
    </div>
  );
};
